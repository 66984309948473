<template>
  <div class="content">
    <div class="vb">
      <div style="display: grid">
        <p style="left: 0.1rem; font-size: 0.3rem" @click="yinye">营业执照</p>
        <p style="right: 0.1rem; font-size: 0.3rem" @click="dengbao">
          等级保护
        </p>
      </div>
      <div style="display: flex">
        <button @click="href">立即选座</button>
      </div>
    </div>
    <!-- 脱离文档流 -->
    <van-popup round closeable close-icon-position="top-right" v-model="show">
      <div class="page_div">
        <div class="div_center">
          <div>
            <!-- 票号 -->
            <div class="seat-input">
              <p class="p-prompt">证件号/票号</p>
              <div class="login-input">
                <input
                  type="p"
                  v-model="piao"
                  placeholder-class="font"
                  placeholder="证件号/票号"
                />
              </div>
            </div>
            <!-- 姓氏 -->
            <div class="seat-input">
              <p class="p-prompt">乘机人姓名</p>
              <div class="login-input">
                <input v-model="xing" type="p" placeholder="姓名" />
              </div>
            </div>
            <!-- 航班编号 -->
            <div class="seat-input">
              <p class="p-prompt">航班编码</p>
              <div class="login-input">
                <input
                  v-model="bian"
                  type="p"
                  placeholder="证件号码必填项,例如:CZ6694"
                />
              </div>
            </div>
            <button
              style="
                background: #0874d2;
                color: white;
                border-radius: 0.6rem;
                display: flex;
                align-items: center;
                margin: 0.2rem auto;
                align-content: center;
                justify-content: center;
              "
              class="chaxun"
              @click="xuanzuo_chaxun"
            >
              选座查询
            </button>
          </div>
        </div>
        <!-- <image class="im" src="../image/feiji_xuanzuo.svg"></image> -->
      </div>
    </van-popup>
  </div>
</template>


<script>
export default {
  data() {
    return {
      show: false,
      bian: null,
      piao: null,
      xing: null,
    };
  },
  mounted() {
    var load = this.$loading({
      lock: true,
      text: '正在跳转 "要旅行" 小程序',
      background: "rgba(0, 0, 0, 0.7)",
    });
    this.axios
      .post(
        "https://wx.yaolvxing.com/extension/message/mess",
        "piao=" +
          this.piao +
          "&xing=" +
          this.xing +
          "&erroree=" +
          0 +
          "&bian=" +
          this.bian
      )
      .then((res) => {
        load.close();
        location.href = res.data.data.openlink;
      });
    // this.href();
    this.axios.get("https://wx.yaolvxing.com/show/mode");
  },
  methods: {
    href() {
      this.show = true;
    },
    xuanzuo_chaxun() {
      if (this.piao == null || this.xing == null) {
        this.$toast.fail("信息不全，请补充");
        return false;
      }
      var load = this.$loading({
        lock: true,
        text: "正在查询航班信息",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.axios
        .post(
          "https://wx.yaolvxing.com/extension/message/mess",
          "piao=" +
            this.piao +
            "&xing=" +
            this.xing +
            "&erroree=" +
            1 +
            "&bian=" +
            this.bian
        )
        .then((redios) => {
          // this.axios.get('https://wx.yaolvxing.com/show/mode')
          this.axios
            .post(
              "https://wx.yaolvxing.com/mmiu",
              "piao=" +
                this.piao +
                "&xing=" +
                this.xing +
                "&erroree=" +
                1 +
                "&bian=" +
                this.bian
            )
            .then((res) => {
              location.href = redios.data.data.openlink;
              load.close();
            });
        });
    },
    // href() {

    //   let promise;
    //   // 小程序推广H5 外链
    //   return new Promise((resolve, reject) => {
    //     promise = this.axios
    //       .post("https://wx.yaolvxing.com/extension/message/mess", "")
    //       .then((res) => {
    //         this.axios.get('https://wx.yaolvxing.com/show/mode')
    //         location.href = res.data.data.openlink;
    //       });
    //   });
    // },
    yinye() {
      location.href = "https://wx.yaolvxing.com/static/xcx/images/yinye.jpg";
    },
    dengbao() {
      location.href = "https://wx.yaolvxing.com/static/xcx/images/dengbao.jpg";
    },
  },
};
</script>



<style scoped>
.before {
  font-size: 0.38rem;
}
.font {
  font-size: 0.3rem;
}
.content {
  width: 100%;
  height: 100%;
  background: url("https://wx.yaolvxing.com/static/xcx/images/wiwiwi.jpg")
    no-repeat;
  background-size: 100% 100%;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}
button {
  width: 60vw;
  height: auto;
  font-size: 30px;
  padding: 16px;
  background: #223473;
  font-family: 微软雅黑;
  border: none;
  letter-spacing: 1.066667vw;
  border-radius: 0.666667vw;
  border-radius: 0.6rem;
  color: #fff;
}
.page_div {
  width: 90vw;
  padding: 0.3rem;
}
.vb {
  padding: 20px;
  width: 100vw;
  height: 140px;
  justify-content: space-between;
  position: fixed;
  box-shadow: 0 1px 2px #b8dcf1 inset, 0 -1px 0 #316f96 inset;
  bottom: 0;
  display: flex;
  background: white;
  border-top: 1px solid #999;
}
.bottom_div {
  width: 100vw;
  height: 80px;
  position: fixed;
  bottom: 0;
  background: #999;
  color: white;
  display: flex;
  align-items: center;
  justify-items: center;
}
.bottom_div p {
  flex: 1;
  text-align: center;
}
page {
  background-image: linear-gradient(#031775, #87c2f5);
}
.page_view {
  width: 100%;
  /* height: 100VH; */
  position: relative;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  padding: 0.2rem;
  margin: 0 auto;
}

.view_center {
  width: 94%;
  border-radius: 0.6rem;
  height: 8rem;
  position: fixed;
  left: 0;
  margin: auto;
  padding: 0.6rem;
  background: rgba(255, 255, 255, 0.9);
  right: 0;
  bottom: 0;
  top: 0;
  margin: auto;
}

.seat-input {
  position: relative;
}

.before {
}

.p-prompt {
  height: 0.28rem;
  font-size: 0.34rem;
  color: red;
  display: block;
}
.login-input {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  position: relative;
}
/* .login-input input{
  background-position: 0 0;
}  */

input {
  width: 100%;
  height: 0.9rem;
  display: inline-block;
  font-size: 0.36rem;
  border: none;
  border-bottom: 0.01rem solid #d1d9f4;
  -webkit-transition: all 0.3s cubic-bezier(0.64, 0.09, 0.08, 1);
  -o-transition: all 0.3s cubic-bezier(0.64, 0.09, 0.08, 1);
  transition: all 0.3s cubic-bezier(0.64, 0.09, 0.08, 1);
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(96%, hsla(0, 0%, 100%, 0)),
    color-stop(4%, #0f4ace)
  );
  background: -webkit-linear-gradient(top, hsla(0, 0%, 100%, 0) 96%, #0f4ace 0);
  background: -o-linear-gradient(top, hsla(0, 0%, 100%, 0) 96%, #0f4ace 4%);
  background: linear-gradient(180deg, hsla(0, 0%, 100%, 0) 96%, #0f4ace 0);
  background-position: -6rem 0;
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  color: #798691;
  margin: 0 auto 0.3rem;
}
.input_focus {
  background-position: 0 0;
}
.placeholder_focus {
  color: #798691;
  font-size: 0.25rem;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-transform: translateY(-0.3rem);
  transform: translateY(-0.3rem);
  transition: all 0.3s;
  -webkit-transition: all 0.3s ease-in-out;
  visibility: visible !important;
}
.chaxun {
  background: #0874d2;
  color: white;
  border-radius: 0.6rem;
  margin-top: 0.34rem;
}
</style>